import React, { useEffect } from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import $ from "jquery";

import ReactPlayer from "react-player";
import MapOfAttractions from "../../components/mapOfAttractions";

const AlicjaWKrainieCzarow = ({ pageContext: { slug } }) => {
  useEffect(() => {
    let clickedNumber = 0;

    var interval = setInterval(() => {
      function changeNumber() {
        if (clickedNumber === 18) {
          clickedNumber = 1;
        } else {
          clickedNumber++;
        }
      }
      changeNumber();

      $("g").removeClass("active");
      $(`g[data-id='${clickedNumber}']`).addClass("active");
      $(".single-point").hide();
      $(`div.single-point[data-id='${clickedNumber}']`).css("display", "flex");
    }, 2500);

    $(".map-section").click(function () {
      clearInterval(interval);
    });
  }, []);

  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Alicja w Krainie Czarów",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/alicja-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #8c0079, #990885, #a80792, #881f79, #d64ac2 )",
        icons: "#8c0079",
        navClass: "alicja-w-krainie-czarow",
        ogImage: require("../../assets/img/portfolio/alicja-w-krainie-czarow_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/alice-in-wonderland/",
      }}
    >
      <PortfolioHeader name="alicja-w-krainie-czarow" height="800" />
      <section
        className="container-fluid alicja-w-krainie-czarow-section-1"
        id="info"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Alicja w Krainie Czarów</h1>
              <h1>Ogród Świateł</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Logo</li>
                <li>Key Visual</li>
                <li>Mapa atrakcji</li>
                <li>Materiały drukowane</li>
                <li>Copywriting</li>
                <li>Film z wydarzenia</li>
                <li>Kampania marketingowa</li>
                <li>Obsługa strony i kampanii w trakcie trwania wydarzenia</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                W Ogrodzie Doświadczeń im. Stanisława Lema w Krakowie powstał
                multimedialny świat Alicji w Krainie Czarów w którym
                wykorzystano miliony kolorowych światełek oraz widowiskowe
                instalacje bohaterów powieści Charlesa Lutwidge’a Dodgsona.
                Przez ponad 4 miesiące mieszkańcy Krakowa (jak i całej Polski)
                mogli cieszyć się bajkowym jesiennym, a następnie zimowym
                spacerem, który zachwycał tak samo dużych, jak i małych gości
                cudownego Ogrodu Świateł.
              </p>
              <p>
                Odpowiadaliśmy za przygotowanie dla firmy CD Lighting z Gdyni
                (organizatora wystawy) projektów kreatywnych tj. logo, key
                visual, mapa atrakcji, materiały drukowane, strona internetowa
                oraz pełną obsługę marketingową w zakresie promocji wydarzenia i
                zrealizowania wyznaczonych celów wyznaczonym terminie.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-2">
        <div className="row">
          <div className="col-md-6 pa-helper">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Logo</h3>
              <p>
                Na potrzeby wystawy zaprojektowaliśmy logo łączące w sobie
                elementy opowieści Alicji w Krainie Czarów oraz magiczny font, a
                to wszystko oczywiście przy zachowaniu odpowiednich standardów
                wymaganych przy późniejszym druku materiałów reklamowych oraz
                formatów interaktywnych.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row-1">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_2.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-3">
        <div className="row">
          <div className="col-xl-7 col-lg-12">
            <div className="inner">
              <h3>Key Visual</h3>
              <p>
                Zaprojektowaliśmy key visual wystawy. Największe wyzwanie
                stanowił tutaj brak materiałów do wykorzystania przed otwarciem
                wystawy. Zaprezentowany projekt miał za zadanie przyciągnąć
                uwagę określonych w strategii grup docelowych, a także pozwolić
                na uspójnienie identyfikacji wizualnej całego wydarzenia.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_3.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-4">
        <div className="row">
          <div className="col-xl-7 col-lg-12">
            <div className="inner">
              <h3>Strona www</h3>
              <p>
                Strona internetowa miała za zadanie oddać magiczny charakter
                miejsca, pozwolić użytkownikom na znalezienie najważniejszych
                informacji o wystawie w szybki i komfortowy sposób, a także
                umożliwić sprzedaż biletów i komunikację aktualnych informacji
                na temat wystawy.
              </p>
              <p>
                Kluczowym aspektem projektu było zakodowanie przez nas strony w
                sposób który pozwalał na jej szybkie i stabilne działanie nawet
                przy ogromnym obciążeniu związanym z dużym zainteresowaniem
                wystawą.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1 no-gutters">
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row-2 no-gutters">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_5.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_6.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-5">
        <div class="inner">
          <h3>Mapa atrakcji</h3>
          <p>
            Przygotowaliśmy dla zwiedzających mapę atrakcji zarówno w formie
            interaktywnej jak i analogowej, dzięki której każdy odwiedzający
            mógł w łatwy sposób poruszać się po Ogrodzie Świateł i znaleźć
            interesujące go atrakcje.
          </p>
        </div>
        {/* <div className="row map-row">
              <div className="col-md-12">
                <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_7.png")}
                    alt=""
                />
              </div>
          </div> */}
        <div className="view--desktop">
          <section className="map-section">
            <div className="container">
              <div className="row no-gutters align-items-center">
                <div className="col-lg-6 column column--map">
                  <MapOfAttractions />
                </div>
                <div className="col-lg-6 column--text">
                  <div className="map-points">
                    <div className="single-point" data-id="1">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-1.png")}
                        alt="Brama i królik"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">1</p>
                        <div className="text-container__text text">
                          <p className="text__name">Brama i Królik</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="2">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-2.png")}
                        alt="Brama do krainy czarów"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">2</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Brama do <br />
                            krainy czarów
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="3">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-3.png")}
                        alt="Tunel do króliczej nory"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">3</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Tunel do <br /> króliczej nory
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="4">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-4.png")}
                        alt="Choinka"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">4</p>
                        <div className="text-container__text text">
                          <p className="text__name">Choinka</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>Codziennie</strong> <br />
                        Pokazy „Candela” 16-metrowej choinki multimedialnej z
                        ponad 30 tysiącami multimedialnych punktów LED. Pokazy
                        odbywają się każdego dnia co 30 minut od momentu
                        otwarcia wystawy. <br />
                        <strong>Czas trwania pokazu: 10 minut.</strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="5">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-5.png")}
                        alt="Królicza nora"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">5</p>
                        <div className="text-container__text text">
                          <p className="text__name">Królicza nora</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="6">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-6.png")}
                        alt="Kot z Cheshire"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">6</p>
                        <div className="text-container__text text">
                          <p className="text__name">Kot z Cheshire</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="7">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-7.png")}
                        alt="Magiczne Kamienie"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">7</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Magiczne <br /> kamienie
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="8">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-8.png")}
                        alt="Znikające obrazy"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">8</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Znikające <br /> obrazy
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="9">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-9.png")}
                        alt="Rada gąsienicy"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">9</p>
                        <div className="text-container__text text">
                          <p className="text__name">Rada Gąsienicy</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="10">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-10.png")}
                        alt="Zimowy ogród"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">10</p>
                        <div className="text-container__text text">
                          <p className="text__name">Zimowy ogród</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="11">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-11.png")}
                        alt="Zmyślona łąka"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">11</p>
                        <div className="text-container__text text">
                          <p className="text__name">Zmyślona łąka</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Pokazy odbywają się każdego dnia co 15 minut.
                        </strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="12">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-12.png")}
                        alt="Labirynt światła"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">12</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Labirynt <br /> światła
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="13">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-13.png")}
                        alt="Zaczarowane przejście"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">13</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zaczarowane <br /> przejście
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="14">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-14.png")}
                        alt="Zwariowany Podwieczorek"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">14</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zwariowany <br /> Podwieczorek
                          </p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="15">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-15.png")}
                        alt="Tęczowa aleja"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">15</p>
                        <div className="text-container__text text">
                          <p className="text__name">Tęczowa aleja</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="16">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-16.png")}
                        alt="Ścieżka multimedialna"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">16</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Ścieżka <br /> multimedialna
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Pokazy odbywają się każdego dnia co 15 minut.
                        </strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="17">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-17.png")}
                        alt="Zamek Królowej Kier"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">17</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zamek <br /> Królowej Kier
                          </p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="18">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-18.png")}
                        alt="Pokazy multimedialne"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">18</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Pokazy laserowe <br /> i multimedialne
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>Codziennie</strong>
                        <br />
                        Pokazy laserowe przy akompaniamencie „Alice’s Theme” z
                        oficjalnej ścieżki dźwiękowej Alicji w Krainie Czarów
                        Tima Burtona. <br />
                        <br />
                        Pokazy laserowe odbywają się co 30 minut, rozpoczynając
                        od godziny 18:15.
                        <br />
                        <strong>Czas trwania pokazu: 5 minut.</strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="19">
                      <img
                        src={require("../../assets/img/portfolio/map/map-figure-19.png")}
                        alt="Święty Mikołaj"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">19</p>
                        <div className="text-container__text text">
                          <p className="text__name">Święty Mikołaj</p>
                          <p className="text__desc"> </p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Atrakcja będzie dostępna codziennie w godzinach od
                          17:00 do 21:00
                        </strong>
                        <br />
                        <br />
                        Święty Mikołaj zagości w naszej Krainie Czarów od 6 do
                        23 grudnia, w pięknym, czerwonym domku - rodem z
                        Laponii. Przy przedświątecznych obowiązkach pomagać mu
                        będzie dzielna Elfka, a cały Ogród Świateł ogarnie magia
                        zbliżających się świąt. 
                        <br />
                        <br />
                        Podczas odwiedzin w domku Świętego Mikołaja będzie
                        możliwość wykonania samodzielnie zdjęć. 
                        <br />
                        <br />
                        <strong>
                          Mając na uwadze środki ostrożności, Święty Mikołaj
                          będzie znajdował się w odpowiednim dystansie od
                          zwiedzających.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="view view--mobile">
          <div className="subpage-header subpage-header--map">
            <h1 className="subpage-header__title">Wersja mobilna</h1>
            <h3 className="subpage-header__subtitle">Mapa do pobrania</h3>
          </div>
          <p className="view__text">
            Sprawdź jakie czekają <br /> na ciebie atrakcje. <br />
          </p>
          <a
            href={require("../../assets/downloads/mapa_atrakcji.pdf")}
            className="btn-alicja btn-alicja--shadow"
            target="_blank"
            rel="noreferrer"
          >
            Pobierz mapę
          </a>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-6">
        <div className="row">
          <div className="col-lg-6 order-helper">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_8.png")}
              alt=""
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_9.png")}
              alt=""
            />
          </div>
          <div className="col-lg-6 order-helper">
            <div className="inner">
              <h3>Kampania marketingowa</h3>
              <p>
                Po ponad 4 miesiącach intensywnej kampanii możemy pochwalić się
                wynikami, które potwierdzają wielki sukces całego projektu. Od
                samego początku publikowane przez nas treści wzbudzały duże
                zainteresowanie, co wygenerowało olbrzymie zasięgi organiczne i
                wpłynęło na bezpłatne wyświetlenia treści publikowanych przez
                profile Alicji w Krainie Czarów.
              </p>
              <p>Oto wyniki z podziałem na kanał Facebook oraz Instagram:</p>
              <h5>Facebook</h5>
              <ul>
                <li>29 546 fanów strony Facebook w 4 miesiące!</li>
                <li>33 649 aktywności pod postami</li>
                <li>2 720 komentarzy pod postami</li>
                <li>1 673 udostępnień postów</li>
                <li>Średni dzienny zasięg organiczny to 32 344 osób!</li>
                <li>Średni dzienny zasięg wirusowy to 26 929 osób!</li>
                <li>
                  Ponad 45 000 osób wzięło udział lub wyraziło zainteresowani
                  wydarzeniami Facebook
                </li>
              </ul>
              <h5>Instagram</h5>
              <ul>
                <li>5 445 followersów na Instagramie w 4 miesiące</li>
                <li>Ponad 17 000 aktywności pod postami</li>
                <li>Średni dzienny zasięg to 21 523 osób</li>
                <li>Współpraca z ponad 75 influencerami z Krakowa i okolic</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-7">
        <div className="inner">
          <h3>Film z wydarzenia</h3>
          <p>
            Zrealizowaliśmy film z wydarzenia ukazujący jak kolorowym i pięknym
            show była wystawa oraz jak ogromne zainteresowanie i zaangażowanie
            wzbudziła po stronie odwiedzających.
          </p>
        </div>
        <div className="video">
          <ReactPlayer
            url="https://wp.adream.pl/wp-content/themes/adream/videos/alicja_video.mp4"
            height="100%"
            width="100%"
            autoplay="true"
            controls="true"
            muted="true"
            playing="false"
            playsinline="true"
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-8">
        <div className="row">
          <div className="offset-xl-6 col-xl-6 offset-lg-5 col-lg-7 offset-md-5 col-md-7">
            <div className="inner">
              <h3>Materiały drukowane</h3>
              <p>
                Na cele promocji wydarzenia przygotowaliśmy szereg projektów do
                druku na różne formaty reklamowe tj. tramwaj, słupy reklamowe,
                citylighty, bilety drukowane i cyfrowe.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/alicja-w-krainie-czarow_main_11.png")}
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default AlicjaWKrainieCzarow;
